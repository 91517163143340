<template>
  <div class="dashboard">
  <v-expansion-panels
      v-model="panel"
      multiple >
  <v-expansion-panel>
  <v-expansion-panel-header>Asetukset</v-expansion-panel-header>
  <v-expansion-panel-content>
  <div class="container">
    <div class="row justify-content-center" style="margin-top: 45px; margin-bottom: 10%;">
      <div class="col-md-10">
        
        <div v-if="error" class="alert alert-danger">{{error}}</div>
        <b-card no-body>
            <b-tabs content-class="mt-3" card>
            <b-tab title="Sisältö asetukset" active>
            <b-card-text>                
              <v-alert type="success" v-show="alert_ajankohtaista">
                  Tallennus onnistui.
                </v-alert>
              <form action="#" @submit.prevent="submit">
                <div class="form-group row">
                  <label for="asetus" class="col-md-4 col-form-label text-md-right">Ajankohtaista</label>

                  <div class="col-md-8">
                    <textarea
                      id="asetus"
                      type="text"
                      class="form-control"
                      name="asetus"
                      rows=8
                      value
                      autofocus
                      v-model="ajankohtaista"
                    />
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <div class="col-md-8 offset-md-4">
                    <button type="submit" class="btn btn-danger  btn-t btn-danger-t">Tallenna muutokset</button>
                  </div>
                </div>
              </form>
            </b-card-text>
            </b-tab>
            <b-tab title="Yleiset asetukset">
              <b-card-text>
                <v-alert type="success" v-show="alert">
                  Tallennus onnistui.
                </v-alert>
                <form action="#" @submit.prevent="submit">
                <div class="form-group row">
                  <label for="email" class="col-md-4 col-form-label text-md-right">Lähetä ilmoitukset osoitteeseen</label>

                  <div class="col-md-8">
                    <input
                      id="email"
                      type="email"
                      class="form-control"
                      name="asetus"
                      value
                      required
                      autofocus
                      v-model="email"
                    />
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <div class="col-md-8 offset-md-4">
                    <button class="btn btn-danger btn-t btn-danger-t" v-on:click="submit_email()">Tallenna muutokset</button>
                  </div>
                </div>
              </form>
              </b-card-text></b-tab>
              <b-tab title="Seuraavat tapahtumat">
                <div class="col-md-8 offset-md-2">
                <b-card-text>
                  <div class="form-group row mt-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left"  style="min-width: 10em;">
                              Päivämäärä
                            </th>
                            <th class="text-left" style="min-width: 22em;">
                              Paikkakunta
                            </th>
                            <th>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in tapahtumat"
                            :key="item.pvm"
                          >
                            <td>{{ item.pvm }}</td>
                            <td>{{ item.paikka }}</td>
                            <td>
                              <button class="btn btn-danger btn-t btn-danger-t" v-on:click="remove_ryhma(item.key)">Poista</button>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <form action="#" @submit.prevent="submit">
                    <div class="form-group row mt-2">
                      <div class="col-md-3">
                        <input
                          id="pvm"
                          type="text"
                          class="form-control"
                          name="asetus3"
                          value
                          required
                          autofocus
                          v-model="pvm"
                        />
                      </div>
                      <div class="col-md-6">
                        <input
                          id="paikka"
                          type="text"
                          class="form-control"
                          name="asetus4"
                          value
                          required
                          autofocus
                          v-model="paikka"
                        />
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-danger btn-t btn-danger-t" v-on:click="submit_ryhma()">Lisää</button>
                      </div>
                    </div>

                  </form>
                  </div>
                </b-card-text>
                
                </div>
              </b-tab>
          </b-tabs>
        </b-card>

        <div class="row justify-content-end mt-2" >
          <div class="col-4">
            <button class="btn btn-danger btn-t btn-danger-t" v-on:click="logout()">Kirjaudu ulos</button>
          </div>
        </div>

      </div>
    </div>
  </div>
  </v-expansion-panel-content>
  </v-expansion-panel>
  <v-expansion-panel>
  <v-expansion-panel-header>Ilmoittautumiset</v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="row  justify-content-center"><div class="col col-md-11">
          <row>
          <H2>Ilmoittautumiset</H2> 
          <download-excel :data="ilmoittautumiset">
            <button type="button" class="btn btn-danger btn-t btn-danger-t">Lataa Excel-tiedostona...</button>
          </download-excel>
          </row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Ryhmä
                  </th>
                  <th class="text-left">
                    Etunimi
                  </th>
                  <th class="text-left">
                    Sukunimi
                  </th>
                  <th class="text-left">
                    Sähköposti
                  </th>
                  <th class="text-left">
                    Puhelin
                  </th>
                  <th class="text-left">
                    Osoite
                  </th>
                  <th class="text-left">
                    Syntymäaika
                  </th>
                  <th class="text-left">
                    Ammattiliitto
                  </th>
                  <th class="text-left">
                    Lomautuksen kesto
                  </th>
                  <th class="text-left">
                    Referenssi
                  </th>
                  <th class="text-left">
                    Oppilaitos
                  </th>
                  <th class="text-left">
                    Koulutus
                  </th>
                  <th class="text-left">
                    Edel. työ
                  </th>
                  <th class="text-left">
                    Haluan vinssi ryhmään koska
                  </th>
                  <th class="text-left">
                    Harrastukset
                  </th>
                  <th class="text-left">
                    Työmarkkina-asema (tyytyväisyys)
                  </th>
                  <th class="text-left">
                    Työmarkkina-tietämys (tyytyväisyys)
                  </th>
                  <th class="text-left">
                    Työnhaku-aktiivisuus (tyytyväisyys)
                  </th>
                  <th class="text-left">
                    Työnhaku-motivaatio (tyytyväisyys)
                  </th>
                  <th class="text-left">
                    Saatu tuki (tyytyväisyys)
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in ilmoittautumiset"
                  :key="item.pvm"
                >
                  <td>{{ item.group_city }}</td>
                  <td>{{ item.firstname }}</td>
                  <td>{{ item.lastname }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.address }} {{ item.postcode }} {{ item.city }} </td>
                  <td>{{ item.birth }}</td>
                  <td>{{ item.union }}</td>
                  <td>{{ item.length }}</td>
                  <td>{{ item.affiliate }}</td>
                  <td>{{ item.university }}</td>
                  <td>{{ item.education }}</td>
                  <td>{{ item.job }}</td>
                  <td>{{ item.reason }}</td>
                  <td>{{ item.hobbies }}</td>
                  <td>{{ item.market_position }}</td>
                  <td>{{ item.market_knowledge }}</td>
                  <td>{{ item.market_activity }}</td>
                  <td>{{ item.market_motivation }}</td>
                  <td>{{ item.market_help }}</td>
                  <td><button type="button" class="btn btn-danger btn-t btn-danger-t" v-on:click="remove_user(item.key)">X</button></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div></div>
    </v-expansion-panel-content>
  </v-expansion-panel>
  </v-expansion-panels>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      error: null,
      ajankohtaista: "",
      email: "",
      alert: false,
      alert_ajankohtaista: false,
      tapahtumat: [],
      pvm: "1.1.2020",
      paikka: "Turku",
      ilmoittautumiset: [],
      panel: [0]
    };
  },
  methods: {
    download() {

    },
    submit() {
      firebase.database().ref('settings/ajankohtaista').set(this.ajankohtaista);
      this.alert_ajankohtaista = true;
    },
    submit_email() {
      firebase.database().ref('settings/alert_email').set(this.email);
      this.alert = true;
    },
    remove_user(key) {
      firebase.database().ref('ilmoittautumiset/' + key).remove();

      this.ilmoittautumiset = [];
      const ilmoituslista = firebase.database().ref('ilmoittautumiset');
      ilmoituslista.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          let childData = childSnapshot.val();
          childData.key = childKey;
          this.ilmoittautumiset.push(childData);
        });
      });
    },
    remove_ryhma(key) {
      firebase.database().ref('tapahtumat/' + key).remove();

      const tapahtumalista = firebase.database().ref('tapahtumat');
      this.tapahtumat = [];
      tapahtumalista.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          const tapahtuma = { 
            "key": childKey,
            "pvm": childData.pvm, 
            "paikka": childData.paikka
          };
        this.tapahtumat.push(tapahtuma);
        });
      });
    },
    submit_ryhma() {
      const tapahtumalista = firebase.database().ref('tapahtumat');
      const tapahtuma = tapahtumalista.push();
      tapahtuma.set({
        "pvm": this.pvm,
        "paikka": this.paikka
      });

      this.tapahtumat = [];
      tapahtumalista.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          const tapahtuma = { 
            "key": childKey,
            "pvm": childData.pvm, 
            "paikka": childData.paikka
          };
        this.tapahtumat.push(tapahtuma);
        });
      });
    },
    logout() {
      firebase
      .auth()
      .signOut()
      .then(() => {
        alert('Kirjauduit ulos');
        this.$router.push('/');
      })
      .catch(error => {
        alert(error.message);
        this.$router.push('/');
      });
    }
  },
  mounted() {
    const alert_setting = firebase.database().ref('settings/alert_email');
    alert_setting.on('value', (snapshot) => {
      const data = snapshot.val();
      this.email = data;
    });


    const ajankohtaista_setting = firebase.database().ref('settings/ajankohtaista');
    ajankohtaista_setting.on('value', (snapshot) => {
      const data = snapshot.val();
      this.ajankohtaista = data;
    });

    const tapahtumalista = firebase.database().ref('tapahtumat');
    tapahtumalista.once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        const tapahtuma = { 
          "key": childKey,
          "pvm": childData.pvm, 
          "paikka": childData.paikka
        };
        this.tapahtumat.push(tapahtuma);
      });
    });

    const ilmoituslista = firebase.database().ref('ilmoittautumiset');
    ilmoituslista.once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        let childData = childSnapshot.val();
        childData.key = childKey;
        this.ilmoittautumiset.push(childData);
      });
    });
  },
  beforeDestroy() {
    
  }
};
</script>

<style>


</style>